<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/landing-page'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
            <button type="button" class="btn btn-warning mb-3 ml-4" @click="FindLp()"
              :disabled="statusRefresh == 0 ? true : false" style="margin-left: 5px">
              <i class="fas fa-undo-alt"></i>
              Refresh
            </button>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">CRM Campaign Field<strong>*</strong></label>
                      <input v-model="formData.CRMtype" type="text" class="form-control" required />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">WP Phone Default<strong>*</strong></label>
                      <input v-model="formData.wpPhoneDefault" type="text" class="form-control" required />
                    </div>
                  </div>
                  
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label for="namePage">Call Phone Default<strong>*</strong></label>
                      <input v-model="formData.CallPhone" type="text" class="form-control" required />
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="card">
              <a href="javascript: void(0);" class="text-dark collapsed" data-toggle="collapse" aria-expanded="false"
                aria-controls="addproduct-img-collapse" v-b-toggle.accordion-01>
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          01
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Wp Phone Number Section</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                  </div>
                </div>
              </a>

              <b-collapse id="accordion-01" accordion="my-accordion" data-parent="#addproduct-accordion">
                <div class="p-4 row">
                  <div class="col-12 mb-4">
                    <label for="">Meta </label>
                    <input class="form-control" v-model="formData.wpPhoneMeta" type="text" />
                  </div>
                  <div class="col-12 mb-4">
                    <label for="">Google </label>
                    <input class="form-control" v-model="formData.wpPhoneGoogle" type="text" />
                  </div>
                  <div class="col-12 mb-4">
                    <label for="">Yandex </label>
                    <input class="form-control" v-model="formData.wpPhoneYandex" type="text" />
                  </div>
                  <div class="col-12 mb-4">
                    <label for="">Tiktok </label>
                    <input class="form-control" v-model="formData.wpPhoneTiktok" type="text" />
                  </div>
                  <div class="col-12 mb-4">
                    <label for="">Youtube </label>
                    <input class="form-control" v-model="formData.wpPhoneYoutube" type="text" />
                  </div>
                </div>
              </b-collapse>
            </div>
            <!-- Wp Message -->

            <div class="card">
              <a href="javascript: void(0);" class="text-dark collapsed" data-toggle="collapse" aria-expanded="false"
                aria-controls="addproduct-img-collapse" v-b-toggle.accordion-02>
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                          02
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">Wp Message Section</h5>
                      <p class="text-muted text-truncate mb-0">
                        Fill all information below
                      </p>
                    </div>
                    <i class="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                  </div>
                </div>
              </a>

              <b-collapse id="accordion-02" accordion="my-accordion" data-parent="#addproduct-accordion">
                <div class="p-4 row">
                  <div class="col-12 mb-4">
                    <label for="">Meta </label>
                    <textarea class="form-control" v-model="formData.wpMeta" rows="4"></textarea>
                  </div>
                  <div class="col-12 mb-4">
                    <label for="">Google </label>
                    <textarea class="form-control" v-model="formData.wpGoogle" rows="4"></textarea>
                  </div>
                  <div class="col-12 mb-4">
                    <label for="">Yandex </label>
                    <textarea class="form-control" v-model="formData.wpYandex" rows="4"></textarea>
                  </div>
                  <div class="col-12 mb-4">
                    <label for="">Tiktok </label>
                    <textarea class="form-control" v-model="formData.wpTiktok" rows="4"></textarea>
                  </div>
                  <div class="col-12 mb-4">
                    <label for="">Youtube </label>
                    <textarea class="form-control" v-model="formData.wpYoutube" rows="4"></textarea>
                  </div>
                </div>
              </b-collapse>
            </div>

          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button type="button" class="btn btn-danger" :disabled="updateSend == 1 ? true : false">
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button type="submit" @click="save()" class="btn btn-success ms-1" :disabled="updateSend == 1 ? true : false">
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// import vue2Dropzone from "vue2-dropzone";
// import Multiselect from "vue-multiselect";

import { API } from "@/state/API2";

import { POPUP } from "@/state/popup";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
// import { formatDate } from "@fullcalendar/common";
// import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  page: {
    title: "Landing Page CRM",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Landing Page CRM",
      items: [
        {
          text: "Landing Page",
        },
        {
          text: "CRM",
          active: true,
        },
      ],
      statusRefresh: 1,
      orderData: [],
      formData: {},
      imgUrl: "http://estetikinternational.com",
      formFields: [
        "CRMtype",
        "CRMPhone",
        "CallPhone",
        "wpMeta",
        "wpGoogle",
        "wpYandex",
        "wpTiktok",
        "wpYoutube",
        "wpPhoneDefault",
        "wpPhoneMeta",
        "wpPhoneGoogle",
        "wpPhoneYandex",
        "wpPhoneTiktok",
        "wpPhoneYoutube",

      ],
      InputCreate: 0,
      SelectBoxCreate: 0,
      manyOption: 1,
      updateSend: 0,
      editor: ClassicEditor,
      editorData:
        "<h3>Hello World!</h3><h5><b>This is an simple editable area.</b></h5>",
      // dropzoneOptions: {
      //   url: "https://httpbin.org/post",
      //   thumbnailWidth: 2000,
      //   maxFilesize: 1,
      //   headers: {
      //     "My-Awesome-Header": "header value",
      //   },
      // },
      value1: null,
      options: [],
      langs: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.updateSend = 1;
      console.log(this.convertToFormData());
      const response = await API.Post(
        API.LandingPage.CRM + this.$route.params.id,
        this.convertToFormData()
      );
      var data;

      if (response.data.status == "slug") {
        data = "Slug has been used before";
        POPUP.popupClickNot(data);
        this.updateSend = 0;
      } else if (response.data.status == "success") {
        this.updateSend = 0;
        return this.$router.push("/landing-page");
      }
    },
    async FindLp() {
      var data;
      this.statusRefresh = 0;

      await API.Get(API.Languages.Index).then((response) => {
        response.data.languages.forEach((element) => {
          this.langs.push(element);
        });
      });
      await API.Get(API.LandingPage.CRMList + this.$route.params.id).then(
        (response) => {
          if (response.data.status == "success") {
            this.formData.CRMtype = response.data.fields.CRMtype;
            this.formData.CRMPhone = response.data.fields.CRMPhone;
            this.formData.CallPhone = response.data.fields.CallPhone;
            this.formData.wpMeta = response.data.fields.wpMeta;
            this.formData.wpGoogle = response.data.fields.wpGoogle;
            this.formData.wpYandex = response.data.fields.wpYandex;
            this.formData.wpTiktok = response.data.fields.wpTiktok;
            this.formData.wpYoutube = response.data.fields.wpYoutube;
            this.formData.wpPhoneDefault = response.data.fields.wpPhoneDefault;
            this.formData.wpPhoneMeta = response.data.fields.wpPhoneMeta;
            this.formData.wpPhoneGoogle = response.data.fields.wpPhoneGoogle;
            this.formData.wpPhoneYandex = response.data.fields.wpPhoneYandex;
            this.formData.wpPhoneTiktok = response.data.fields.wpPhoneTiktok;
            this.formData.wpPhoneYoutube = response.data.fields.wpPhoneYoutube;

            data = "Your work has been refresh";
            POPUP.popupClick(data);

            this.statusRefresh = 1;
          } else {
            data = "Your work could not be renewed";
            POPUP.popupClickNot(data);

            this.statusRefresh = 1;
          }
        }
      );
    },
  },
};
</script>
<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}

.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}

img {
  margin: 5px;
}
</style>
